// src/components/ProdutosVendidos.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Box } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

function ProdutosVendidos() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/produtos_vendidos`)
      .then((response) => {
        const sortedData = response.data.produtos.sort(
          (a, b) => b.quantidade - a.quantidade
        );
        setData(sortedData);
      })
      .catch((error) => {
        console.error('Erro:', error);
      });
  }, []);

  // Renderização customizada do label:
  // Se a largura da barra for pequena (menos de 40px), renderiza o nome do produto fora da barra.
  // Caso contrário, exibe o nome dentro da barra com cor branca.
  const renderCustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    const offset = 5;
    if (width < 40) {
      return (
        <text
          x={x - offset}
          y={y + height / 2}
          fill="#000"
          textAnchor="end"
          dominantBaseline="middle"
          fontSize={12}
        >
          {value}
        </text>
      );
    }
    return (
      <text
        x={x + offset}
        y={y + height / 2}
        fill="#fff"
        textAnchor="start"
        dominantBaseline="middle"
        fontSize={12}
      >
        {value}
      </text>
    );
  };

  return (
    <Card
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" color="textSecondary" gutterBottom align="center">
          Produtos Vendidos
        </Typography>
        {/* Alteramos o alinhamento para "flex-start" para justificar à esquerda */}
        <Box display="flex" justifyContent="flex-start" alignItems="center" height="100%">
          {data.length > 0 ? (
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                layout="vertical"
                data={data}
                // Reduzimos a margem esquerda para aproximar o gráfico da borda
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                {/* Eixo numérico para as quantidades */}
                <XAxis type="number" stroke="#757575" tick={{ fontSize: 12 }} />
                {/* Eixo das categorias (produto) – ocultamos os ticks */}
                <YAxis type="category" dataKey="produto" stroke="#757575" reversed tick={false} />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#f5f5f5',
                    border: 'none',
                    borderRadius: 4,
                  }}
                  labelStyle={{ fontWeight: 'bold' }}
                  cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
                />
                <Bar dataKey="quantidade" fill="#1A73E8" radius={[5, 0, 0, 5]} barSize={30}>
                  <LabelList dataKey="produto" content={renderCustomLabel} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Typography variant="body1" color="textSecondary" align="center">
              Carregando dados ou nenhum produto vendido.
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProdutosVendidos;
